<template>
  <div style="background:#fff;padding:15px;">
    <van-row>
      <van-col span="6">
        <van-image
        fit="cover"
        round
        width="4rem"
        height="4rem"
        :src="avatar"
      />
      </van-col>
      <van-col span="18">
        <h5 style="margin:13px 0 5px 0;">{{ nickname }}</h5>
        <div>
          <!-- <van-tag size="medium" style="">买家ID {{ buyer.buyerId }}</van-tag> -->
          <!-- <van-tag type="primary" size="medium" style="">买家ID {{ buyer.buyerId }}</van-tag>
          <template v-if="shareholder !== ''">
            &nbsp;<van-tag type="primary" size="medium" style="">{{ shareholder }}</van-tag>
          </template> -->
        </div>
      </van-col>
    </van-row>
  </div>
  <template v-if="(typeof storeId !== 'undefined')">
    <van-cell is-link :title="memberId ? '我的会员' : '成为会员'" icon="shop-o" :to="{ name: 'RegisterMember' }" style="margin:7px 0 0 0;" />
  </template>
  <van-grid :border="false" column-num="4" style="margin:7px 0 0 0;">
    <van-grid-item @click="onDeposit">
      <p>
        <small>&yen; {{ deposit }}</small>
      </p>
      <p>
        <small>储值</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <p>
        <small>{{ points }}</small>
      </p>
      <p>
        <small>积分</small>
      </p>
    </van-grid-item>
    <van-grid-item :to="{ name: 'WithdrawalOld' }">
      <p>
        <small>&yen; {{ balance }}</small>
      </p>
      <p>
        <small>可提现</small>
      </p>
    </van-grid-item>
    <van-grid-item :to="{ name: 'Withdrawal' }">
      <p>
        <small>&yen; {{ cash }}</small>
      </p>
      <p>
        <small>累计收益</small>
      </p>
    </van-grid-item>
  </van-grid>
  <van-cell-group title="我的订单"></van-cell-group>
  <van-grid :column-num="5">
    <!-- <van-grid-item icon="paid" text="待成团" :dot="orderTotalForWaitGroup > 0" @click="onGoOrderList(0)" /> -->
    <van-grid-item icon="qr" text="待使用" :dot="orderTotalForWaitUse > 0" @click="onGoOrderList(3)" />
    <van-grid-item icon="records" text="待发货" :dot="orderTotalForWaitDeliver > 0" @click="onGoOrderList(1)" />
    <van-grid-item icon="tosend" text="已发货" :dot="orderTotalForDelivered > 0" @click="onGoOrderList(2)" />
    <van-grid-item icon="after-sale" text="退款中" :dot="orderTotalForRefunding > 0" @click="onGoOrderList(6)" />
    <van-grid-item icon="orders-o" text="全部" @click="onGoOrderList(0)" />
  </van-grid>
  <van-cell-group title="常用">
    <van-cell title="店内付款" is-link :to="{ name: 'FreePay' }" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="我的邀请" is-link :to="{ name: 'Invitation' }" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="设置头像" is-link :to="{ name: 'Profile' }" />
    <!--<van-cell title="积分兑换" is-link to="/user" />-->
    <!-- <van-cell title="我的权益" is-link to="/rights" v-if="(typeof storeId !== 'undefined')" /> -->
    <van-cell title="联系商家" is-link to="/chat" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="清理缓存" is-link :to="{ name: 'ClearCache' }" />
  </van-cell-group>
  <van-cell-group title="店员" v-if="staffId">
    <van-cell title="我销售的" is-link :to="{ name: 'SalespersonOrder' }" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="预约我的" is-link :to="{ name: 'TechnicianOrder' }" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="我的顾客" is-link :to="{ name: 'MyCustomer' }" v-if="(typeof storeId !== 'undefined')" />
    <van-cell title="联盟卡商品" is-link :to="{ name: 'unionGoods' }" v-if="(typeof storeId !== 'undefined')" />
    <!-- <van-cell title="会员信息" is-link :to="{ name: 'MyCustomer' }" v-if="(typeof storeId !== 'undefined')" /> -->
    <!-- <van-cell title="付款二维码" is-link :to="{ name: 'FreePayQrCode' }" v-if="(typeof storeId !== 'undefined')" /> -->
  </van-cell-group>
  <van-cell-group title="技师" v-if="staffId && staffType">
    <van-cell title="会员信息" is-link :to="{ name: 'bindMember' }" v-if="(typeof storeId !== 'undefined')" />
  </van-cell-group>
   <!-- v-if="staffId" --><!-- 收款码只有成为员工后成为销售才会有 -->
  <van-cell-group title="收款码" v-if="staffId && staffSale">
    <van-cell title="二维码收款" is-link @click="createCode()"/>
  </van-cell-group>
  <van-cell-group title="账号">
    <!-- <van-cell title="换绑手机号" :value="userPhone" is-link :to="{ name: 'AccountWechatSettings' }" /> -->
    <!-- <van-cell title="微信设置" is-link :to="{ name: 'AccountWechatSettings' }" /> -->
    <van-cell title="退出账号" is-link @click="onLogout()" />
  </van-cell-group>
  <!--
  <template v-if="rights">
    <van-cell-group title="权益">
      <van-cell :title="salesperson.name" value="我要开单" is-link to="/user/staff/sale" v-if="typeof salesperson !== 'undefined'" />
    </van-cell-group>
  </template>
  -->
  <rights />
  <tabbar active="user" />
  <van-overlay :show="codeShow" @click="codeShow = false">
    <div class="wrapper">
      <div class="box">
        <van-image @click.stop fit="cover" width="180px" height="180px" :src="codeUrl"/>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Tabbar from '../components/Tabbar'
import Rights from '@/components/Rights.vue'
import { Toast } from 'vant'
import QRCode from 'qrcode'

export default {
  components: {
    Rights,
    Tabbar
  },
  setup () {
    const post = inject('post')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      buyer: {
        user: {}
      },
      orderTotalForWaitGroup: 0,
      orderTotalForWaitDeliver: 0,
      orderTotalForWaitUse: 0,
      orderTotalForDelivered: 0,
      orderTotalForRefunding: 0,
      shareholder: '',
      rights: false,
      salesperson: undefined,
      store: {},
      cash: 0,
      balance: 0,
      deposit: 0,
      points: 0,
      storeId: Cookies.get('storeId'),
      buyerId: Cookies.get('buyerId'),
      memberId: Cookies.get('memberId'),
      userId: Cookies.get('userId'),
      staffId: Cookies.get('staffId'),
      staffType: '',
      staffSale: '',
      virtualAssets: [],
      nickname: '',
      avatar: '',
      userPhone: '',
      isMember: false,
      isStaff: false,
      codeUrl: '',
      codeShow: false
    })
    const buildImage = (content) => {
      QRCode.toDataURL(content).then(image => {
        state.codeUrl = image
        state.codeShow = true
        console.log(image)
      })
    }
    const createCode = () => {
      var url = window.location.protocol + '//' + window.location.host + '/freePay?storeId=' + state.storeId + '&salescollectionpersonId=' + state.staffId
      buildImage(url)
    }
    const onLogout = () => {
      // post('/user.bindWechat', {
      //   userId: state.userId,
      //   bind: false
      // }).then(res => {
      // })
      Cookies.remove('salespersonId')
      Cookies.remove('inviterId')
      Cookies.remove('memberId')
      Cookies.remove('staffId')
      Cookies.remove('staffType')
      Cookies.remove('staffSale')
      Cookies.remove('buyerId')
      Cookies.remove('userId')
      Cookies.remove('token')
      useRouter.push({
        name: 'Login',
        query: {
          redirect: window.location.pathname,
          role: 'BUYER',
          initOnLogout: true
        }
      })
    }
    const onGoOrderList = (tab) => {
      useRouter.push({
        name: 'OrderList',
        query: {
          tab: tab
        }
      })
    }
    const onRegisterCustomer = () => {
      useRouter.push({
        path: '/user/registerShopMember'
      })
    }
    const onDeposit = () => {
      if (!state.isMember) {
        Toast('请先成为会员')
        return
      }
      useRouter.push({
        name: 'Deposit'
      })
    }
    const init = () => {
      if (state.staffId) {
        post('/staff.get', {
          userId: Cookies.get('userId'),
          storeId: Cookies.get('storeId')
        }).then(res => {
          if (res.code === 0) {
            state.staffType = res.data.type
            state.staffSale = res.data.sale
          }
        })
      }
      post('/user.get', {
        userId: state.userId
      }).then(res => {
        // alert(res.data.profit)
        state.userPhone = res.data.phone
        state.cash = res.data.profit
        state.balance = res.data.balance
      })
      post('/buyer.get', {
        buyerId: state.buyerId
      }).then(res => {
        state.nickname = res.data.nickname
        state.avatar = res.data.avatar
        if (typeof Cookies.get('wechatNickname') !== 'undefined') {
          state.nickname = Cookies.get('wechatNickname')
          state.avatar = Cookies.get('wechatHeadimgurl')
        }
      })
      post('/member.get', {
        userId: state.userId,
        storeId: state.storeId
      }).then(res => {
        if (res.code === 0) {
          state.isMember = true
          state.points = res.data.points
          state.deposit = res.data.deposit
        }
      })
      post('/order.list', {
        productScope: 'GENERAL',
        buyerId: state.buyerId,
        status: 'WAIT_GROUP',
        storeId: state.storeId
      }).then(res => {
        state.orderTotalForWaitGroup = res.data.totalElements
      })
      post('/order.list', {
        productScope: 'GENERAL',
        buyerId: state.buyerId,
        status: 'WAIT_DELIVER',
        storeId: state.storeId
      }).then(res => {
        state.orderTotalForWaitDeliver = res.data.totalElements
      })
      post('/order.list', {
        productScope: 'GENERAL',
        buyerId: state.buyerId,
        status: 'DELIVERED',
        storeId: state.storeId
      }).then(res => {
        state.orderTotalForDelivered = res.data.totalElements
      })
      post('/order.list', {
        productScope: 'GENERAL',
        buyerId: state.buyerId,
        status: 'WAIT_USE',
        storeId: state.storeId
      }).then(res => {
        state.orderTotalForWaitUse = res.data.totalElements
      })
      post('/order.list', {
        productScope: 'GENERAL',
        buyerId: state.buyerId,
        status: 'REFUNDING',
        storeId: state.storeId
      }).then(res => {
        state.orderTotalForRefunding = res.data.totalElements
      })
    }
    onMounted(() => {
      console.log(state.staffId)
      console.log(state.staffType)
      init()
    })
    return {
      ...toRefs(state),
      onGoOrderList,
      onRegisterCustomer,
      onDeposit,
      onLogout,
      createCode
    }
  }
}
</script>

<style scoped>
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .wrapper .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    background: #FFFFFF;
  }
</style>
